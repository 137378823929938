<template>
  <div class="intro ios-testflight-upgrade">
      <div class="step-title">iOS 升级</div>
      <div class="step-box">
        <div @click="downloadInstall(['download-button-ios-testflight'], $event)" class="download-button download-button-ios-testflight app-store-like">
        <span>
          <img class="app-icon" src="@/assets/images/icon_app.png"/>
        </span>
          <span>
          <ul>
            <li class="app-title">狗急加速</li>
            <li class="app-intro">将跳转至 TestFlight</li>
            <li class="app-get">升级</li>
          </ul>
        </span>
        </div>
      </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
  export default {
    name: 'IOSTestflightUpgrade',
    components: {},
    setup () {
      let stores = useStore()
      function downloadInstall (className, event) {
        stores.commit('changeToPath', {tag:'toIosTestflightFaq', value: true})
        let resp = window.install('', className)
        if (!resp) event.preventDefault()
        return resp
      }
      return {
        downloadInstall
      }
    }
  }
</script>

<style scoped>

</style>
